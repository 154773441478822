<template>
  <div
    class="suggestions"
  >
    <div class="suggestions__headline">
      {{ $tv('suggestions', 'searchResults') }}
    </div>
    <div class="suggestions__results">
      <div
        v-for="(suggestion, index) in suggestions.slice(0, maxSuggestions )"
        :key="index"
      >
        <div
          class="suggestion-result"
          @click="handleSuggestionClick(suggestion.Sku, index)"
        >
          <CustomerItemNumberIcon />
          <span>{{ suggestion.CustomerSku }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import CustomerItemNumberIcon from '~/assets/icons/customer_item_number.svg?component'

const {suggestions, maxSuggestions} = defineProps({
  suggestions: {
    type: Array,
    default: () => []
  },
  maxSuggestions: {
    type: Number,
    default: 4
  }
})

const emit = defineEmits(['suggestion-clicked'])

const handleSuggestionClick = (sku, index) => {
  emit('suggestion-clicked', {sku, index})
}
</script>
<style lang="scss">
.suggestions {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 1rem;

  &__headline {
    font-size: 1rem;
    font-weight: $base-text-font-weight-bold;
    color: $color__fst__primary;
    margin-bottom: .5rem;
  }

  &__results {
    display: flex;
    gap: 0.75rem;
    flex-wrap: wrap;

    .suggestion-result:hover {
      border: 0.063rem solid $color__fst__primary__hover;
      box-shadow: 0 0 0 0.063rem $color__fst__primary__hover;
    }

    .suggestion-result {
      position: relative;
      border-radius: 0.2rem;
      border: 0.063rem solid $color__fst__primary;
      box-sizing: border-box;
      transition: border 0.3s;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      color: $color__fst__primary;
      background-color: $color__fst__white;

      span {
        margin-left: 1.25rem;
      }

      .icon--svg {
        position: absolute;
        top: .125rem;
        left: .375rem;
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }
}
</style>
